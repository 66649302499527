import React, {Component} from 'react';
import Header from './components/Header';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import portfolioData from './portfolioData';

class App extends Component {
    render() {
        return (
            <div>
                <Header portfolioData={portfolioData}/>
                <Portfolio portfolioData={portfolioData}/>
                <About portfolioData={portfolioData}/>
                <Resume portfolioData={portfolioData}/>
                <Testimonials portfolioData={portfolioData}/>
                <Footer/>
            </div>
        );
    }
}

export default App;
