let portfolioData = {
    "name": "Kaelah Marvin",
    "description": "I make art and stuff",
    "socialLinks": [
        {
            "name": "linkedin",
            "url": "https://www.linkedin.com/in/kaelah-marvin-b8783b169/",
            "className": "fa fa-linkedin"
        }
    ],
    "aboutMe": "This is where my description will go.",
    "email": "marvinkaelah@gmail.com",
    "work": [
        {
            "id": 1,
            "companyName": "Worlds Enterprises, Inc.",
            "position": "Jr. Software Developer",
            "startDate": "June 2019",
            "endDate": "August 2021"
        },
        {
            "id": 2,
            "companyName": "Edozo",
            "position": "Intern",
            "startDate": "May 2017",
            "endDate": "July 2017"
        }
    ],
    "education": [
        {
            "id": 1,
            "school": "Collin College",
            "specialization": "Associates of Applied Science in Game Art",
            "startDate": "August 2019",
            "endDate": "Current"
        },
        {
            "id": 2,
            "school": "Princeton High School",
            "specialization": "",
            "startDate": "August 2012",
            "endDate": "June 2016"
        }
    ],
    "portfolio": [
        {
            "id": 1,
            "name": "Mothership Character Designs",
            "description": "Character designs for my friends and me",
            "thumbnailurl": "https://assets.kaelahmarvin.com/portfolio/mothership-characters-thumbnail.jpg",
            "url": "https://assets.kaelahmarvin.com/portfolio/mothership-characters.jpg"
        }
    ],
    "testimonials": [
        {
            "id": 1,
            "description": "Kaelah came to work for us as an intern in the summer, she was a great asset! She was thrown in at the deep end. She had to learn a completely new system and write data extraction software using a new software development language. She also learned the internal systems we use, and was part of the team to deliver a request for proposal to a potential new client. Throughout she was a delight to work with and was unfailingly friendly and positive. We miss her!",
            "name": "Luke West - Director at Edozo"
        }
    ]
}

export default portfolioData
