import React, {Component} from 'react';

export default class Porfolio extends Component {
    render() {
        let portfolioData = this.props.portfolioData;
        return (
            <section id="portfolio">
                <div className="row">
                    <div className="twelve columns collapsed">
                        <h1>Some things I made that I'm proud of</h1>
                        <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            {
                                portfolioData.portfolio && portfolioData.portfolio.map((item) => {
                                    return (
                                        <div className="columns portfolio-item" key={item.id}>
                                            <div className="item-wrap">
                                                <a href={item.url}>
                                                    <img src={item.thumbnailurl} className="item-img" alt=""/>
                                                    <div className="overlay">
                                                        <div className="portfolio-item-meta">
                                                            <h5>{item.name}</h5>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
