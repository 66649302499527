import React, {Component} from 'react';

export default class Testimonials extends Component {
    render() {
        let portfolioData = this.props.portfolioData;
        return (
            <section id="testimonials">
                <div className="text-container">
                    <div className="row">
                        <div className="two columns header-col">
                            <h1><span>Testimonials</span></h1>
                        </div>
                        <div className="ten columns flex-container">
                            <div className="flexslider">
                                <ul className="slides">
                                    {
                                        portfolioData.testimonials && portfolioData.testimonials.map((item) => {
                                            return (
                                                <li key={item.id}>
                                                    <blockquote>
                                                        <p>
                                                            {item.description}
                                                        </p>
                                                        <cite>{item.name}</cite>
                                                    </blockquote>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            {/* div.flexslider ends */}
                        </div>
                        {/* div.flex-container ends */}
                    </div>
                    {/* row ends */}
                </div>
                {/* text-container ends */}
            </section>
        );
    }
}
