import React, {Component} from 'react';

function Item(props) {
    const item = props.item;

    if(item.specialization.length > 0) {
        return (
            <div>
                <div className="info">
                    <h4>{item.school},</h4>
                    <span/>
                    <em className="date">{item.startDate} - {item.endDate}</em>
                </div>
                <em>{item.specialization}</em>
                <br/>
                <br/>
            </div>
        );
    } else {
        return (
            <div>
                <div className="info">
                    <h4>{item.school},</h4>
                    <span/>
                    <em className="date">{item.startDate} - {item.endDate}</em>
                </div>
            </div>
        );
    }
}

export default class Resume extends Component {
    render() {
        let portfolioData = this.props.portfolioData;
        return (
            <section id="resume">
                <div className="row work">
                    <div className="three columns header-col">
                        <h1><span>Work</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {
                            portfolioData.work && portfolioData.work.map((item) => {
                                return (
                                    <div className="row item" key={item.id}>
                                        <div className="twelve columns">
                                            <div className="info">
                                                <h4>{item.companyName},</h4>
                                                <span/>
                                                <em className="date">{item.startDate} - {item.endDate}</em>
                                            </div>
                                            <em>{item.position}</em>
                                            <br/>
                                            <br/>
                                        </div>

                                    </div>

                                )
                            })
                        }
                    </div>
                </div>

                <div className="row education">

                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        {
                            portfolioData.education && portfolioData.education.map((item) => {
                                return (
                                    <div className="row item" key={item.id}>
                                        <div className="twelve columns">
                                            <Item item={item}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </section>
        );
    }
}
